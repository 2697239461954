import React from "react";
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';

import {
  Grid,
  Typography
} from '@material-ui/core';

import Button from './Button';
import Header from './Header';
import Footer from './Footer';

import { globalStyles, globalColors } from './styles/style-constants';

class Therapist extends React.Component {
  render() {
    const { classes } = this.props;

    return (
      <div>
        <Header visible={true}  />
        <Grid container alignItems="center" justify="flex-start" className={classNames(classes.homeWrapper)}>
          <Grid item xs={12} className={classNames(classes.widthWrapper, classes.secondPanelText)}>
            <Typography className={classes.headerH1}>It's a calling.</Typography>
            <Typography className={classes.headerBody1} style={{ marginTop: 24, marginBottom: 24 }}>Sunstone aims to ensure that all therapists who want to provide psychedelic-assisted therapy have the opportunity to gain the skills and practical experience they need to effectively support patients through their treatment.</Typography>
            <a rel="noopener noreferrer" target="_blank" href="https://sunstone.sunstonetherapies.com/interested/therapist" className={classes.link}>
              <Button style={{ width: 263 }}>Sign Up</Button>
            </a>
          </Grid>
        </Grid>

        <Grid container alignItems="center" justify="center" className={classNames(classes.blankWrapper)}>
          <Grid item xs={12} className={classNames(classes.widthWrapper, classes.secondPanelText)}>
            <Grid container alignItems="center" justify="center" spacing={3} direction="column">
              <Grid item>
                <img alt="therapist" src="/therapist/Group6.png" />
              </Grid>
              <Grid item align="center" sm={12} md={8}>
                <Typography className={classes.h4}>Sunstone’s therapists are getting hands-on experience in clinical trials with oversight and guidance from some of the most experienced people in the field. Their collective expertise and insight are informing the development of resources and training and shape the future of psychedelic therapy.</Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid container alignItems="center" justify="center" className={classNames(classes.patternWrapper)}>
          <Grid item xs={12} className={classNames(classes.widthWrapper, classes.secondPanelText)}>
            <Grid container alignItems="center" justify="center" spacing={3}>
              <Grid item align="left" sm={12} md={5}>
                <Grid className={classes.quoteContainer}>
                  <img alt="therapist" src="/therapist/quote.png" />
                  <br/>
                  <br/>
                  <Typography className={classNames(classes.quote, classes.h4Blue)}>For me, I'm inspired by many of my patients, in their courage to explore the darkest aspects of themselves and the infinite, what they teach me, through their eyes, and how effortlessly so many let go of their fear of death, once they remember.</Typography>
                
                  <br/>
                  <br/>
                  <Grid container alignItems="center">
                    <Grid item className={classes.quoteBackBrian}>
                      <img alt="therapist" width="92" src="/homepage/briancircle.png" />
                    </Grid>
                    <Grid item>
                      <Typography style={{ marginLeft: 24 }} className={classes.label1Blue}>
                        Brian Richards, Psyd<br/>Sunstone Therapist
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item align="left" sm={12} md={5}>
                <Grid className={classes.quoteContainer}>
                  <img alt="therapist" src="/therapist/quote.png" />
                  <br/>
                  <br/>
                  <Typography className={classNames(classes.quote, classes.h4Blue)}>One of the powerful promises of psychedelic-assisted healing is that it goes far beyond symptom reduction, frequently reconnecting people with what they most value in life and deeper fulfillment in living.</Typography>
                
                  <br/>
                  <br/>
                  <br/>
                  <Grid container alignItems="center">
                    <Grid item className={classes.quoteBackMark}>
                      <img alt="logo" width="92" src="/homepage/markcircle.png" />
                    </Grid>
                    <Grid item>
                      <Typography style={{ marginLeft: 24 }} className={classes.label1Blue}>
                        Mark Bates, PhD<br/>Sunstone Therapist
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid container alignItems="center" justify="center" className={classNames(classes.blueWrapper)}>
          <Grid item xs={12} className={classNames(classes.widthWrapper, classes.secondPanelText)}>
            <Grid container alignItems="center" justify="center" spacing={3} direction="column">
              <Grid item align="center" sm={12} md={8}>
                <Typography className={classNames(classes.h2White)}>We’d like to keep in touch. Please sign up to receive updates about upcoming opportunities.</Typography>
                <br/>
                <br/>

                <a rel="noopener noreferrer" target="_blank" href="https://sunstone.sunstonetherapies.com/interested/therapist" className={classes.link}>
                  <Button style={{ width: 263, border: `2px solid white` }}>Sign Up</Button>
                </a>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid container alignItems="center" justify="center" className={classNames(classes.blankWrapper)}>
          <Grid item xs={12} className={classNames(classes.widthWrapper, classes.secondPanelText)}>
            <Grid container alignItems="center" justify="center" spacing={3} direction="row">
              <Grid item align="center" sm={12} md={6}>
                <img alt="logo" className={classes.responsiveImg} src="/therapist/brightlearninginframe1.png" />
              </Grid>
              <Grid item align="flex-start" sm={12} md={6}>
                <Typography className={classNames(classes.h2Blue)}>Learning with Sunstone</Typography>
                <br/>
                <Typography className={classes.bullet}>Sunstone provides on-site and virtual training for therapists seeking to safely and effectively support patients through psychedelic therapy.</Typography>
                <br/>
                <Typography className={classes.bullet}>Our learning model quickly identifies key areas for improvement so therapists can focus their time and effort where they need it most.</Typography>
                <br/>
                <Typography className={classes.bullet}>Our training is based upon real-world experience with highly trained, highly effective psychedelic therapists.</Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Footer />
      </div>
    )
  }

}

const styles = theme => ({
  headerH1: {
    ...globalStyles.h1(theme),
    color: globalColors.white
  },
  bullet: {
    position: 'relative',
    fontWeight: 400,
    color: 'black',
    marginLeft: 24,
    '&:before': {  
      content: '""',
      top: 6,
      bottom: 0,
      left: -24,
      right: 0,
      backgroundImage: 'url(/homepage/Ellipse29.png)',
      backgroundRepeat: 'no-repeat',
      position: 'absolute'
    }
  },
  headerBody1: {
    ...globalStyles.body1,
    color: globalColors.white,
    maxWidth: 569
  },
  headerH2: {
    ...globalStyles.h2(theme),
    color: globalColors.white
  },
  h2Blue: {
    ...globalStyles.h2(theme),
    color: globalColors.blue,
    lineHeight: '25.2px'
  },
  h2: {
    ...globalStyles.h2(theme),
  },
  h2White: {
    ...globalStyles.h2(theme),
    color: 'white'
  },
  h4: {
    ...globalStyles.h4,
    color: globalColors.blue
  },
  h4Blue: {
    ...globalStyles.h4,
    color: globalColors.blue
  },
  label1Blue: {
    ...globalStyles.label1,
    color: globalColors.blue
  },
  quoteBackBrian: {
    borderRadius: '50%',
    width: 92,
    height: 92,
    backgroundColor: globalColors.darkPink 
  },
  quoteBackMark: {
    borderRadius: '50%',
    width: 92,
    height: 92,
    backgroundColor: globalColors.lightPink 
  },
  responsiveImg: {
    maxWidth: '100%'
  },
  quote: {
    position: 'relative',
    // '&:before': {  
    //   content: '""',
    //   top: 0,
    //   left: 0,
    //   width: 22,
    //   height: 16,
    //   position: 'absolute',
    //   backgroundImage: 'url(/therapist/quote.png)',
    // }
  },
  blankWrapper: {
    paddingTop: 56,
    paddingBottom: 56
  },
  blueWrapper: {
    paddingTop: 98,
    paddingBottom: 98,
    backgroundColor: theme.globalColors.blue
  },
  grayWrapper: {
    paddingTop: 98,
    paddingBottom: 98,
    backgroundColor: theme.globalColors.grey
  },
  patternWrapper: {
    paddingTop: 56,
    paddingBottom: 56,
    position: 'relative',
    zIndex: 1,
    backgroundColor: theme.globalColors.lightestGrey,
    '&:before': {  
      content: '""',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      backgroundImage: 'url(https://sunstone-storage-public.s3.amazonaws.com/website-resources/Vector.png)',
      position: 'absolute',
      opacity: 0.25,
      zIndex: -1
    }
  },
  homeWrapper: {
    marginTop: 176,
    minHeight: 636,
    position: 'relative',
    zIndex: 1,
    backgroundColor: theme.globalColors.lightestGrey,
    '&:before': {  
      content: '""',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      backgroundPosition: 'center',
      backgroundImage: 'url(/therapist/main.png)',
      backgroundSize: 'cover',
      position: 'absolute',
      opacity: 1,
      zIndex: -1
    }
  },
  widthWrapper: globalStyles.widthWrapper(theme),
  widthWrapperFull: {
    width: '100%',
    ...globalStyles.widthWrapper(theme)
  },
  link: {
    textDecoration: 'none !important'
  },
  quoteContainer: {
    background: 'white',
    padding: 33,
    boxShadow: '2px 2px 11px -2px rgba(0, 0, 0, 0.1)',
    minHeight: 391
  }
});

export default withStyles(styles)(Therapist);