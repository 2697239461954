import React, { Component } from 'react';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { remCalc } from '../util/style-util';
import { globalColors } from '../styles/style-constants';

import rootTheme from './themes/rootTheme';

const baseTheme = {
  panelPaddingTopBottom: 80,
  h3HeaderPaddingTopBottom: 36,
  h4HeaderPaddingTopBottom: 16,
  globalColors: {
    blackOnlyForButtons: globalColors.blackOnlyForButtons,
    white: globalColors.white,
    digitalBlack: globalColors.digitalBlack,
    mediumBlackIconOnly: global.mediumBlackIconOnly,
    mediumGrey: globalColors.mediumGrey,
    lightestGrey: globalColors.lightestGrey,
    greyDisabled: globalColors.greyDisabled,
    ...globalColors
  },
  overrides: {
    MuiTab: {
      textColorPrimary: {
        fontSize: remCalc(14),
        fontWeight: 600,
        color: globalColors.black,
        '&$selected': {
          color: globalColors.black,
        }
      }
    },
    MuiButton: {
      contained: {
        '&$disabled': {
          backgroundColor: '#d2d2d2',
          color: '#FFF'
        }
      }
    }
  }
};

class ThemeSelector extends Component {
  render() {
    const brandTheme = rootTheme;

    const theme = createMuiTheme({ ...baseTheme, ...brandTheme.extendedTheme()});
    theme.typography = { ...theme.typography, ...brandTheme.typography(theme, remCalc) };
    theme.extraTypography = { ...brandTheme.extraTypography(theme, remCalc) };

    return (
      <MuiThemeProvider theme={theme}>
        {this.props.children}
      </MuiThemeProvider>
    );
  }
}

export default ThemeSelector;
