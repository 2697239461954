import React from "react";
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';

import {
  Grid,
  Typography
} from '@material-ui/core';

import Button from './Button';
import Header from './Header';
import Footer from './Footer';

import ResourceCard from './ResourceCard';

import { globalStyles, globalColors } from './styles/style-constants';

class PartnerPage extends React.Component {
  render() {
    const { classes } = this.props;

    return (
      <div>
        <Header visible={true}  />
        <Grid container alignItems="center" justify="flex-end" className={classNames(classes.homeWrapper)}>
          <Grid item sm={12} md={4} align="right" className={classNames(classes.secondPanelText)} style={{ marginRight: 78 }}>
            <Typography className={classes.headerDisplay}>Partner with us.</Typography>
            <Typography className={classes.headerBody1} style={{ marginTop: 24, marginBottom: 24 }}>Sunstone accelerates the development of research and market-ready sites by utilizing experience and insights from Richards center. Sunstone provides digital systems and tools to expedite site development and research.</Typography>
            <a rel="noopener noreferrer" target="_blank" href="https://sunstone.sunstonetherapies.com/interested/center" className={classes.link}>
              <Button style={{ width: 263 }}>Sign Up</Button>
            </a>
          </Grid>
        </Grid>

        <Grid id="what-we-offer" container alignItems="center" justify="center" className={classNames(classes.blankWrapper)}>
          <Grid align="center" className={classNames(classes.widthWrapperFull)}>
            <Grid style={{ width: '100%' }} container alignItems="center" justify="center" direction="column">
              <Grid item>
                <Typography className={classes.h3} style={{ marginBottom: 72 }}>Sunstone supports psychedelic drug companies to:</Typography>
              </Grid>
              
              <Grid direction="row" alignItems="center" justify="center" container spacing={6}>
                <Grid item align="center" sm={12} md={4} className={classes.offerItem}>
                  <img alt="trial" src="/partner/Group110.png"/>
                  <Typography className={classes.h3}>Prepare</Typography>
                  <Typography>Preparing select centers to conduct research, upskill staff, and provide system to support psychedelic therapy PX</Typography>
                </Grid>
                <Grid item align="center" sm={12} md={4} className={classes.offerItem}>
                  <img alt="therapist" src="/partner/Group108.png"/>
                  <Typography className={classes.h3}>Coordinate</Typography>
                  <Typography>Assisting pharma sponsors to land and coordinate trials in qualified research sites, including therapy and trial design</Typography>
                </Grid>
                <Grid item align="center" sm={12} md={4} className={classes.offerItem}>
                  <img alt="partner" src="/partner/Group109.png"/>
                  <Typography className={classes.h3}>Accelerate</Typography>
                  <Typography>Digitally platforming trials and PX insights to accelerate and roll-up learning across a network of sites</Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid container id="news-events" alignItems="center" justify="center" className={classNames(classes.patternWrapper)}>
          <Grid item xs={12} align="left" className={classNames(classes.widthWrapper, classes.secondPanelText)}>
            <Typography className={classes.h1}>Creating center of excellence</Typography>
            <br/>

            <Typography>The Bill Richards Center for Healing</Typography>
            <br/>
          
            <Grid container alignItems="center" justify="center" spacing={3}>
              <Grid item align="center">
                <img alt="logo" className={classes.responsiveImg} src="/partner/Rectangle141.png" />
              </Grid>
              <Grid item align="center">
                <img alt="logo" className={classes.responsiveImg} src="/partner/Rectangle142.png" />
              </Grid>
              <Grid item align="center">
                <img alt="logo" className={classes.responsiveImg} height="336" src="/partner/Rectangle143.png" />
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid container alignItems="center" justify="center" className={classNames(classes.blueWrapper)}>
          <Grid item xs={12} className={classNames(classes.widthWrapper, classes.secondPanelText)}>
            <Grid container alignItems="center" justify="center" spacing={3} direction="column">
              <Grid item align="center" sm={12} md={8}>
                <Typography className={classNames(classes.h2White)}>Interested in facilitating psychedelic therapy?</Typography>
                <br/>
                <br/>

                <a rel="noopener noreferrer" target="_blank" href="https://sunstone.sunstonetherapies.com/interested/center" className={classes.link}>
                  <Button style={{ width: 263, border: `2px solid white` }}>Sign Up</Button>
                </a>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid container id="news-events" alignItems="center" justify="center" className={classNames(classes.patternWrapper)}>
          <Grid item align="center" className={classNames(classes.widthWrapper, classes.secondPanelText)}>
            <Typography className={classes.h1}>News and Events</Typography>

            <Grid container spacing={1} direction="row">
              <Grid item align="left" sm={12} md={4}>
                <a className={classes.link} rel="noopener noreferrer" target="_blank" href="https://compasspathways.com/the-aquilino-cancer-center/">
                  <ResourceCard
                    title="The Aquilino Cancer Center - Compass Pathways"
                    description="Compass Pathways"
                    tagText={"News"}
                    tagBackground={globalColors.darkPink20}
                    image={"/homepage/news4.png"}
                    tagColor={globalColors.darkPink}
                  />
                </a>
              </Grid>
              
              <Grid item align="left" sm={12} md={4}>
                <a className={classes.link} rel="noopener noreferrer" target="_blank" href="https://www.forbes.com/sites/abbierosner/2020/09/16/could-group-therapy-make-psychedelic-drug-treatments-more-accessible/#64e990bd37ff">
                  <ResourceCard
                    title="Could Group Therapy Make Psychedelic Drug Treatments More Accessible?"
                    description="Abbie Rosner | Forbes"
                    tagText={"News"}
                    tagBackground={globalColors.darkPink20}
                    image={"/homepage/news1.png"}
                    tagColor={globalColors.darkPink}
                  />
                </a>
              </Grid>

              <Grid item align="left" sm={12} md={4}>
                <a className={classes.link} rel="noopener noreferrer" target="_blank" href="https://sunstonetherapies.medium.com/designing-for-the-healing-experience-58f5d3ebff15">
                  <ResourceCard
                    title="Designing for the Healing Experience"
                    description="Chris Baer | Medium"
                    tagText={"News"}
                    image={"/homepage/news5.png"}
                    tagBackground={globalColors.darkPink20}
                    tagColor={globalColors.darkPink}
                  />
                </a>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Footer />
      </div>
    )
  }

}

const styles = theme => ({
  headerDisplay: {
    ...globalStyles.display(theme),
    color: globalColors.black
  },
  headerH1: {
    ...globalStyles.h1(theme),
    color: globalColors.black
  },
  h1: {
    ...globalStyles.h1(theme),
    color: globalColors.blue,
  },
  h3: {
    ...globalStyles.h3(theme),
    color: globalColors.blue,
    marginBottom: 36,
    marginTop: 36
  },
  bullet: {
    position: 'relative',
    fontWeight: 400,
    color: 'black',
    marginLeft: 24,
    '&:before': {  
      content: '""',
      top: 6,
      bottom: 0,
      left: -24,
      right: 0,
      backgroundImage: 'url(/homepage/Ellipse29.png)',
      backgroundRepeat: 'no-repeat',
      position: 'absolute'
    }
  },
  headerBody1: {
    ...globalStyles.body1,
    color: globalColors.black,
    maxWidth: 569
  },
  headerH2: {
    ...globalStyles.h2(theme),
    color: globalColors.white
  },
  h2Blue: {
    ...globalStyles.h2(theme),
    color: globalColors.blue,
    lineHeight: '25.2px'
  },
  h2: {
    ...globalStyles.h2(theme),
  },
  h2White: {
    ...globalStyles.h2(theme),
    color: 'white'
  },
  h4: {
    ...globalStyles.h4,
    color: globalColors.blue
  },
  h4Blue: {
    ...globalStyles.h4,
    color: globalColors.blue
  },
  label1Blue: {
    ...globalStyles.label1,
    color: globalColors.blue
  },
  quoteBackBrian: {
    borderRadius: '50%',
    width: 92,
    height: 92,
    backgroundColor: globalColors.darkPink 
  },
  quoteBackMark: {
    borderRadius: '50%',
    width: 92,
    height: 92,
    backgroundColor: globalColors.lightPink 
  },
  responsiveImg: {
    maxWidth: '100%'
  },
  quote: {
    position: 'relative',
    // '&:before': {  
    //   content: '""',
    //   top: 0,
    //   left: 0,
    //   width: 22,
    //   height: 16,
    //   position: 'absolute',
    //   backgroundImage: 'url(/therapist/quote.png)',
    // }
  },
  blankWrapper: {
    paddingTop: 56,
    paddingBottom: 56
  },
  blueWrapper: {
    paddingTop: 98,
    paddingBottom: 98,
    backgroundColor: theme.globalColors.blue
  },
  grayWrapper: {
    paddingTop: 98,
    paddingBottom: 98,
    backgroundColor: theme.globalColors.grey
  },
  patternWrapper: {
    paddingTop: 56,
    paddingBottom: 56,
    position: 'relative',
    zIndex: 1,
    backgroundColor: theme.globalColors.lightestGrey,
    '&:before': {  
      content: '""',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      backgroundImage: 'url(https://sunstone-storage-public.s3.amazonaws.com/website-resources/Vector.png)',
      position: 'absolute',
      opacity: 0.25,
      zIndex: -1
    }
  },
  homeWrapper: {
    marginTop: 176,
    minHeight: 636,
    position: 'relative',
    zIndex: 1,
    backgroundColor: theme.globalColors.lightestGrey,
    '&:before': {  
      content: '""',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      backgroundPosition: 'center',
      backgroundImage: 'url(/partner/partner-head.png)',
      backgroundSize: 'cover',
      position: 'absolute',
      opacity: 1,
      zIndex: -1,
      [theme.breakpoints.down('md')]: {
        backgroundPosition: 'right'
      }
    }
  },
  widthWrapper: globalStyles.widthWrapper(theme),
  widthWrapperFull: {
    width: '100%',
    ...globalStyles.widthWrapper(theme)
  },
  link: {
    textDecoration: 'none !important'
  },
  quoteContainer: {
    background: 'white',
    padding: 33,
    boxShadow: '2px 2px 11px -2px rgba(0, 0, 0, 0.1)',
    minHeight: 391
  }
});

export default withStyles(styles)(PartnerPage);
