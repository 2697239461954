import axios from 'axios';
import React from 'react'
import ReactJkMusicPlayer from 'react-jinke-music-player'
import 'react-jinke-music-player/assets/index.css'

import {
  Grid
 } from '@material-ui/core';

class Playlist extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      playlist: []
    };
  }

  async componentDidMount() {
    const api = axios.create();

    const { data } = await api.request({
      url: 'https://sunstone-storage-public.s3.amazonaws.com/playlist/playlist2.json',
      method: 'GET'
    });

    this.setState({
      playlist: data
    });
  }

  render() {
    const { playlist = [] } = this.state;

    if (playlist.length === 0) {
      return <div></div>
    }

    const options = {
      // mobileMediaQuery: '(max-width: 2px) and (orientation : portrait)'
      showReload: false,
      showThemeSwitch: false,
      mode: 'full',
      audioLists: playlist,
      autoPlay: false
    };

    return (
      <Grid container className="App">
        <ReactJkMusicPlayer {...options} />
      </Grid>
    );
  }
}

export default Playlist;