import React from "react";

import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  TextField
 } from '@material-ui/core';

import './App.css';
import Api from './Api';

// const credentials = [
//   'MD',
//   'APRN',
//   'MA',
//   'BC',
//   'PhD',
//   'BSN',
//   'RN',
//   'PA',
//   'LCSW',
//   'LMFT',
//   'LCSW-C',
//   'PA-C',
//   'LCSW-C RYT',
//   'MPH',
//   'LCPC'
// ];

const interests = [
  'Patient Experience',
  'Ketamine',
  'Whole Person Care',
  'Oncology',
  'Training',
  'Depression',
  'Research',
  'Resources',
  'Drug Trials',
  'PTSD',
  'Insights',
  'Addiction',
  'MDMA',
  'Other',
  'Psilocybin'
];

// const trainings = [
//   'MAPS',
//   'Compass',
//   'CIIS'
// ]

class OrganizationForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedInterests: {}
    };
  }

  submitForm() {
    Api.submitForm('organization', {
      'ORGANIZATION/FACILITY NAME': this.state.organization,
      'EMAIL ADDRESS': this.state.emailAddress,
      'TITLE': this.state.title,
      'WEBSITE URL': this.state.websiteUrl,
      'STATE': this.state.state,
      'COUNTY': this.state.county,
      'CITY/TOWN': this.state.city,
      'CONTACT FIRST NAME': this.state.firstName,
      'CONTACT LAST NAME': this.state.lastName,
      'CONTACT TITLE': this.state.title,
      'CONTACT EMAIL': this.state.emailAddress,
      'INTERESTS': Object.keys(this.state.selectedInterests).join(', '),
    });

    alert('Thank you for your submission.');
  }

  selectInterest(c) {
    const { selectedInterests } = this.state;

    if (selectedInterests[c]) {
      delete selectedInterests[c];
    } else {
      selectedInterests[c] = true;
    }

    this.setState({ selectedInterests });
  }

  render() {
    const { organization, websiteUrl, state, county, city, firstName, lastName, title, emailAddress } = this.state;
    const { selectedInterests } = this.state;

    return (
      <Grid container className="App">
        <Grid xs={12} style={{ paddingLeft: 16 }}>
          <h1>Let’s work together.</h1>
          <p>We believe there is a lot we can learn from each other.</p>
          <br/>
          <br/>
        </Grid>

        <Grid xs={12} sm={6}>
          <div className="field-wrapper">
            <FormControl fullWidth component="fieldset">
              <FormLabel align="left" component="legend" className="form-label">Organization / Facility Name</FormLabel>
              <TextField
                onChange={e => this.setState({ organization: e.target.value })}
                value={organization}
                variant="outlined"
              />
            </FormControl>
          </div>
        </Grid>

        <Grid xs={12} sm={6}>
          <div className="field-wrapper">
            <FormControl fullWidth component="fieldset">
              <FormLabel align="left" component="legend" className="form-label">Website URL</FormLabel>
              <TextField
                onChange={e => this.setState({ websiteUrl: e.target.value })}
                value={websiteUrl}
                variant="outlined"
              />
            </FormControl>
          </div>
        </Grid>

        <Grid xs={12} sm={6}>
          <Grid container>
            <Grid xs={12} sm={6}>
              <div className="field-wrapper">
                <FormControl fullWidth component="fieldset">
                  <FormLabel align="left" component="legend" className="form-label">State</FormLabel>
                  <TextField
                    onChange={e => this.setState({ state: e.target.value })}
                    value={state}
                    variant="outlined"
                  />
                </FormControl>
              </div>
            </Grid>
            <Grid xs={12} sm={6}>
              <div className="field-wrapper">
                <FormControl fullWidth component="fieldset">
                  <FormLabel align="left" component="legend" className="form-label">County</FormLabel>
                  <TextField
                    onChange={e => this.setState({ county: e.target.value })}
                    value={county}
                    variant="outlined"
                  />
                </FormControl>
              </div>
            </Grid>
          </Grid>
        </Grid>

        <Grid xs={12} sm={6}>
          <div className="field-wrapper">
            <FormControl fullWidth component="fieldset">
              <FormLabel align="left" component="legend" className="form-label">City/Town</FormLabel>
              <TextField
                onChange={e => this.setState({ city: e.target.value })}
                value={city}
                variant="outlined"
              />
            </FormControl>
          </div>
        </Grid>

        <Grid xs={12} style={{ paddingLeft: 16 }}>
          <p><b>Primary Contact Information:</b></p>
        </Grid>

        <Grid xs={12} sm={6}>
          <div className="field-wrapper">
            <FormControl fullWidth component="fieldset">
              <FormLabel align="left" component="legend" className="form-label">First Name</FormLabel>
              <TextField
                onChange={e => this.setState({ firstName: e.target.value })}
                value={firstName}
                variant="outlined"
              />
            </FormControl>
          </div>
        </Grid>

        <Grid xs={12} sm={6}>
          <div className="field-wrapper">
            <FormControl fullWidth component="fieldset">
              <FormLabel align="left" component="legend" className="form-label">Last Name</FormLabel>
              <TextField
                onChange={e => this.setState({ lastName: e.target.value })}
                value={lastName}
                variant="outlined"
              />
            </FormControl>
          </div>
        </Grid>

        <Grid xs={12} sm={6}>
          <div className="field-wrapper">
            <FormControl fullWidth component="fieldset">
              <FormLabel align="left" component="legend" className="form-label">Title</FormLabel>
              <TextField
                onChange={e => this.setState({ title: e.target.value })}
                value={title}
                variant="outlined"
              />
            </FormControl>
          </div>
        </Grid>

        <Grid xs={12} sm={6}>
          <div className="field-wrapper">
            <FormControl fullWidth component="fieldset">
              <FormLabel align="left" component="legend" className="form-label">Email Address</FormLabel>
              <TextField
                onChange={e => this.setState({ emailAddress: e.target.value })}
                value={emailAddress}
                variant="outlined"
              />
            </FormControl>
          </div>
        </Grid>

        <Grid xs={12} sm={6}>
          <div className="field-wrapper">
            <FormControl fullWidth component="fieldset">
              <FormLabel align="left" component="legend" className="form-label">Interests (select all that apply)</FormLabel>
              <Grid container>
                { interests.map(c => (
                  <Grid xs={12} sm={6}>
                    <FormGroup>
                      <FormControlLabel
                        control={<Checkbox value={c} checked={selectedInterests[c] != null} onChange={this.selectInterest.bind(this, c)} />}
                        label={c}
                      />
                    </FormGroup>
                  </Grid>
                ))}
              </Grid>
            </FormControl>
          </div>
        </Grid>

        <Grid xs={12} style={{ paddingLeft: 16 }}>
          <div style={{ textAlign: 'left', marginTop: 32 }}>
            <button
              onClick={this.submitForm.bind(this)}
              className="App-button"
            >
              Submit
            </button>
          </div>
        </Grid>
      </Grid>
    );
  }
}

export default OrganizationForm;