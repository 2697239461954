import React, { Fragment } from "react";
import classNames from 'classnames';

import { withStyles } from '@material-ui/core/styles';

import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";

import ThemeSelector from './styles/ThemeSelector';
import {
  CssBaseline,
  Hidden,
  Grid,
  Typography
} from '@material-ui/core';

import { globalStyles, globalColors } from './styles/style-constants';

import IndividualForm from './IndividualForm';
import OrganizationForm from './OrganizationForm';
import InterestedForm from './InterestedForm';

import Playlist from './Playlist';
import Button from './Button';
import ResourceCard from './ResourceCard';

import Header from './Header';
import Footer from './Footer';
import Therapist from './Therapist';
import PartnerPage from './PartnerPage';
import Team from './Team';
import StayInTouch from './StayInTouch';
import Newsletter from './Newsletter';

export default function BasicExample() {
  return (
    <ThemeSelector>
      <CssBaseline />
      <Router>
        <Switch>
          <Route exact path="/">
            <StyledHome />
          </Route>
          <Route exact path="/therapist">
            <Therapist />
          </Route>
          <Route exact path="/partner">
            <PartnerPage />
          </Route>
          <Route exact path="/team">
            <Team />
          </Route>
          <Route exact path="/playlist">
            <Playlist />
          </Route>
          <Route path="/interested">
            <InterestedForm />
          </Route>
          <Route path="/individual">
            <IndividualForm />
          </Route>
          <Route path="/organization">
            <OrganizationForm />
          </Route>
        </Switch>
      </Router>
    </ThemeSelector>
  );
}

// You can think of these components as "pages"
// in your app.

class Home extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      visible: true,
      email: '',
      prevScrollpos: 0,
      firstName: '',
      lastName: '',
      contactEmail: '',
      message: '',
      showAllEvents: false
    };
  }

  render() {
    const { classes } = this.props;
    const { showAllEvents } = this.state;

    return (
      <div>
        <Header transparentHeaderY={300}>
          <Grid container alignItems="flex-end" justify="center" className={classNames("HomeEntryPanel")}>
            <Grid item style={{ zIndex: 2 }}>
              <div className={classes.widthWrapperFull} style={{ zIndex: 2 }}>
                <Grid container spacing={3} direction="row" alignItems="flex-end">
                  <Hidden lg>
                    <Grid item sm={1}/>
                  </Hidden>
                  <Grid item sm={11} md={9}>
                    <Typography className={classNames(classes.h1, "quote")}>"It gave me this really beautiful understanding of our potential. I don't feel like crying anymore. It has taken away the hopeless feeling."</Typography>
                    <Typography className="quote quote-small">— Recipient of Psilocybin therapy</Typography>
                  </Grid>
                </Grid>
              </div>
            </Grid>
          </Grid>
        </Header>

        <Grid container alignItems="center" justify="center" className={classNames(classes.patternWrapper)}>
          <Grid item align="center" className={classNames(classes.widthWrapper, classes.secondPanelText)}>
            <Typography className={classes.h2}>Whole Person Healing for all.</Typography>
            <Typography style={{ marginTop: 24 }}>We exist to accelerate on-the-ground learning and operations necessary to approve and scale psychedelic therapies in the medical setting.</Typography>
          </Grid>
        </Grid>

        <Grid id="what-we-offer" container alignItems="center" justify="center" className={classNames(classes.blankWrapper)}>
          <Grid align="center" className={classNames(classes.widthWrapperFull)}>
            <Grid style={{ width: '100%' }} container alignItems="center" justify="center" direction="column">
              <Grid item>
                <Typography className={classes.whatWeOffer}>What we offer</Typography>
              </Grid>
              
              <Grid direction="row" alignItems="center" justify="center" container spacing={6}>
                <Grid item align="center" sm={12} md={4} className={classes.offerItem}>
                  <img alt="trial" src="/homepage/Group5.png"/>
                  <Typography className={classes.h3}>Trials and Research</Typography>
                  <Typography>Our research data informs continual development of guidelines and best practices for delivering a quality patient experience and operating trials safely, and will be available to our partners on our digital platform.</Typography>
                </Grid>
                <Grid item align="center" sm={12} md={4} className={classes.offerItem}>
                  <img alt="therapist" src="/homepage/Group6.png"/>
                  <Typography className={classes.h3}>Therapist Training</Typography>
                  <Typography>We provide therapists with the skills they need to make sure patients feel safe and supported. Our experiential training simulations prepare guides for a range of personalities, situations, and conversations.</Typography>
                </Grid>
                <Grid item align="center" sm={12} md={4} className={classes.offerItem}>
                  <img alt="partner" src="/homepage/Group7.png"/>
                  <Typography className={classes.h3}>Partner With Us</Typography>
                  <Typography>Thoughtful consideration to set and setting is essential. We consult with local practices to assess feasibility and readiness, and help them adapt their spaces to optimize their ability to provide the highest standard of care.</Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Hidden smDown>
          <Grid id="how-we-help" container alignItems="center" justify="flex-end" direction="column" className={classNames(classes.healingWrapper)}>
            <Grid item align="center" className={classNames(classes.widthWrapperFull)}>
              <Grid container alignItems="center" justify="center">
                <Grid item sm={12} md={7}>
                  <Typography className={classes.h2White}>Creating Centers for Healing</Typography>
                  <Typography className={classes.h4White} style={{ marginTop: 24 }}>Join Sunstone's growing network of centers spearheading trials in psychedelic therapy and psycho-oncology, and training staff and therapist in patient experience standards.</Typography>
                  <br/>
                  <br/>
                  <br/>

                  <a rel="noopener noreferrer" href="/partner" className={classes.link}>
                    <Button  style={{ width: 263 }}>Learn more</Button>
                  </a>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Hidden>
        <Hidden mdUp>
          <Grid id="how-we-help" container justify="center" alignItems="center" className={classNames(classes.widthWrapperFull)}>
            <Grid item align="center" style={{ marginTop: 24 }}>
              <img alt="logo" style={{ maxWidth: '100%' }} src={'/homepage/healing.png'} />
              <Typography  style={{ marginTop: 24 }} className={classes.h2}>Creating Centers for Healing</Typography>
              <Typography className={classes.h4Blue} style={{ marginTop: 24, marginBottom: 24 }}>Join Sunstone's growing network of centers spearheading trials in psychedelic therapy and psycho-oncology, and training staff and therapist in patient experience standards.</Typography>

              <a rel="noopener noreferrer" href="/partner" className={classes.link}>
                <Button style={{ width: '90%', background: 'white', color: globalColors.blue, border: `2px solid ${globalColors.blue}` }}>
                  Learn more
                </Button>
              </a>
              <br/>
              <br/>
              <br/>
              <br/>
            </Grid>
          </Grid>
        </Hidden>

        <Grid container alignItems="center" justify="flex-end" direction="column" className={classNames(classes.interestedWrapper)} style={{ padding: 0 }}>
          <Grid item className={classNames(classes.widthWrapperFull)}>
            <Grid container>
              <Grid item sm={12} md={6} style={{ padding: 64, paddingTop: 144, paddingBottom: 144 }}>
                <Typography className={classes.h2White}>Are you a patient interested in participating in an upcoming trial?</Typography>
                <br/>
                <br/>

                <div>
                  <a className={classes.link} href="https://sunstone.sunstonetherapies.com/interested" target="_blank" rel="noopener noreferrer">
                    <Button style={{ width: 263, zIndex: 2, border: '2px solid white' }}>Let us help</Button>
                  </a>
                </div>
              </Grid>
              <Grid item sm={12} md={6} className={classes.areYouATherapist} style={{ padding: 64, paddingTop: 144, paddingBottom: 144 }}>
                <Typography className={classes.h2White}>Are you a therapist interested in providing psilocybin therapy?</Typography>
                <br/>
                <br/>

                <div>
                  <a className={classes.link} href="/therapist">
                    <Button style={{ width: 263, zIndex: 2, border: '2px solid white' }}>Learn More</Button>
                  </a>
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid container alignItems="center" justify="flex-end" direction="column" className={classNames(classes.manishWrapper)}>
          <Grid item align="center" className={classNames(classes.widthWrapperFull)}>
            <Grid container alignItems="center" justify="center">
              <Grid item sm={12} md={7}>
                <Typography className={classes.h1White}>“Preliminary studies suggest that psilocybin has the potential to help address the deep emotional and psychological distress that cancer patients face.”</Typography>
                <br/>
                <Typography style={{ color: 'white' }}>— Dr. Manish Agrawal, CEO Sunstone Therapies</Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid id="what-we-offer" container alignItems="center" justify="center" className={classNames(classes.blankWrapper)}>
          <Grid className={classNames(classes.widthWrapperFull)}>
            <Grid container spacing={3} alignItems="space-between" justify="space-between">
              <Grid item sm={12} md={8}>
                <Typography className={classes.passionPurpose}>Passion and Purpose</Typography>
                <br/>
                <Typography>The Sunstone team was brought together by shared compassion for those who suffer and a genuine wish to broaden their access to treatment. Our backgrounds in medicine, business, and design provide us with unique perspectives and skills necessary to define the standards of patient experience.</Typography>
              </Grid>
              <Grid item align="right" sm={12} md={4}>
                <div>
                  <a className={classes.link} href="/team">
                    <Button style={{ maxWidth: 275 }}>Meet our team</Button>
                  </a>
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid container id="news-events" alignItems="center" justify="center" className={classNames(classes.patternWrapper)}>
          <Grid item align="center" className={classNames(classes.widthWrapper, classes.secondPanelText)}>
            <Typography className={classes.h1}>News and Events</Typography>

            <Grid container spacing={1} direction="row">
              <Grid item align="left" sm={12} md={4}>
                <a className={classes.link} rel="noopener noreferrer" target="_blank" href="https://www.psychologytoday.com/us/blog/pathways-progress/202103/the-tip-the-iceberg-addressing-mental-health-in-cancer">
                  <ResourceCard
                    title="The Tip of the Iceberg: Addressing Mental Health in Cancer"
                    description="Manish Agrawal, M.D. | Psychology Today"
                    tagText={"News"}
                    tagBackground={globalColors.darkPink20}
                    image={"/homepage/Rectangle44.png"}
                    tagColor={globalColors.darkPink}
                  />
                </a>
              </Grid>

              <Grid item align="left" sm={12} md={4}>
                <a className={classes.link} rel="noopener noreferrer" target="_blank" href="https://compasspathways.com/the-aquilino-cancer-center/">
                  <ResourceCard
                    title="The Aquilino Cancer Center - Compass Pathways"
                    description="Compass Pathways"
                    tagText={"News"}
                    tagBackground={globalColors.darkPink20}
                    image={"/homepage/news4.png"}
                    tagColor={globalColors.darkPink}
                  />
                </a>
              </Grid>
              
              <Grid item align="left" sm={12} md={4}>
                <a className={classes.link} rel="noopener noreferrer" target="_blank" href="https://www.forbes.com/sites/abbierosner/2020/09/16/could-group-therapy-make-psychedelic-drug-treatments-more-accessible/#64e990bd37ff">
                  <ResourceCard
                    title="Could Group Therapy Make Psychedelic Drug Treatments More Accessible?"
                    description="Abbie Rosner | Forbes"
                    tagText={"News"}
                    tagBackground={globalColors.darkPink20}
                    image={"/homepage/news1.png"}
                    tagColor={globalColors.darkPink}
                  />
                </a>
              </Grid>

              { showAllEvents && (
                <Fragment>
                  <Grid item align="left" sm={12} md={4}>
                    <a className={classes.link} rel="noopener noreferrer" target="_blank" href="https://sunstonetherapies.medium.com/whole-person-healing-for-all-3e6aa9a6d139">
                      <ResourceCard
                        title="Whole Person Healing for All"
                        description="Paul Thambi, M.D. with Ron Mis | Medium"
                        tagText={"News"}
                        image={"/homepage/news3.png"}
                        tagBackground={globalColors.darkPink20}
                        tagColor={globalColors.darkPink}
                      />
                    </a>
                  </Grid>

                  <Grid item align="left" sm={12} md={4}>
                    <a className={classes.link} rel="noopener noreferrer" target="_blank" href="https://sunstonetherapies.medium.com/designing-for-the-healing-experience-58f5d3ebff15">
                      <ResourceCard
                        title="Designing for the Healing Experience"
                        description="Chris Baer | Medium"
                        tagText={"News"}
                        image={"/homepage/news5.png"}
                        tagBackground={globalColors.darkPink20}
                        tagColor={globalColors.darkPink}
                      />
                    </a>
                  </Grid>

                  <Grid item align="left" sm={12} md={4}>
                    <a className={classes.link} rel="noopener noreferrer" target="_blank" href="https://medium.com/@genslerpodcast/psycho-oncology-an-intersection-of-design-medicine-the-mind-in-healing-1631d382907b">
                      <ResourceCard
                        title="Psycho-oncology: An intersection of design, medicine & the mind in healing"
                        description="Gensler Design Exchange Podcast | Medium"
                        tagText={"News"}
                        image={"/homepage/news2.png"}
                        tagBackground={globalColors.darkPink20}
                        tagColor={globalColors.darkPink}
                      />
                    </a>
                  </Grid>
                </Fragment>

                
              )}
            </Grid>

            { !showAllEvents && (
              <Grid container justify="center" alignItems="center">
                <Grid item style={{ marginTop: 24 }}>
                  <Button style={{ background: 'white', color: globalColors.blue, border: `2px solid ${globalColors.blue}` }} onClick={() => this.setState({ showAllEvents: true })}>
                    Expand to see all
                  </Button>
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>

        <Newsletter />
        <StayInTouch />

        <Footer />
      </div>
    );
  }
}

const styles = theme => ({
  fullScreenModal: {
    position: 'absolute',
    top: 0,
    right: 0,
    left: 0,
    bottom: 0,
    height: '100vh',
    background: 'white'
  },
  modalInner: {
    height: '100%',
    background: 'white'
  },
  teamWrapper: {

  },
  cardBackContainer: {
    background: globalColors.darkPink,
    color: `${globalColors.white} !important`,
    height: '100%',
    width: '100%',
    padding: 24
  },
  h1: {
    ...globalStyles.h1(theme),
    color: globalColors.blue,
    paddingBottom: theme.h3HeaderPaddingTopBottom
  },
  h1White: {
    ...globalStyles.h1(theme),
    color: globalColors.white
  },
  h2: {
    ...globalStyles.h2(theme),
    color: globalColors.blue
  },
  h2Mobile: {
    ...globalStyles.h2(theme),
    color: globalColors.blue,
    fontSize: 24,
    marginTop: 8
  },
  h2White: {
    ...globalStyles.h2(theme),
    color: globalColors.white
  },
  h3: {
    ...globalStyles.h3(theme),
    color: globalColors.blue,
    paddingTop: theme.h3HeaderPaddingTopBottom,
    paddingBottom: theme.h3HeaderPaddingTopBottom
  },
  h3White: {
    ...globalStyles.h3(theme),
    color: globalColors.white
  },
  h4White: {
    ...globalStyles.h4,
    color: globalColors.white
  },
  h4Blue: {
    ...globalStyles.h4,
    color: globalColors.blue
  },
  label1White: {
    ...globalStyles.label1,
    color: globalColors.white,
    marginTop: 8,
    marginBottom: 32,
    [theme.breakpoints.down('md')]: {
      marginTop: 4,
      marginBottom: 4
    }
  },
  body1White: {
    ...globalStyles.body1,
    color: globalColors.white
  },
  whatWeOffer: {
    ...globalStyles.h1(theme),
    color: globalColors.blue,
    paddingBottom: theme.panelPaddingTopBottom
  },
  passionPurpose: {
    ...globalStyles.h1(theme),
    color: globalColors.blue
  },
  teamContainer: {
    [theme.breakpoints.down('md')]: {
      marginTop: '32px !important'
    }
  },
  signupItem: {
    [theme.breakpoints.down('md')]: {
      marginTop: 16
    }
  },
  teamItem: {
    minHeight: 360,
    [theme.breakpoints.down('md')]: {
      width: '100%',
      backgroundSize: 'cover',
      minHeight: 400
    }
  },
  areYouATherapist: {
    borderLeft: '1px solid white',
    [theme.breakpoints.down('sm')]: {
      borderLeft: 0
    }
  },
  secondPanelText: {
    paddingTop: theme.panelPaddingTopBottom,
    paddingBottom: theme.panelPaddingTopBottom,
    [theme.breakpoints.down('md')]: {
      paddingTop: `${theme.panelPaddingTopBottom / 2}px !important`,
      paddingBottom: `${theme.panelPaddingTopBottom / 2}px !important`,
    }
  },
  blankWrapper: {
    width: '100%',
    paddingTop: theme.panelPaddingTopBottom,
    paddingBottom: theme.panelPaddingTopBottom
  },
  offerItem: {
    [theme.breakpoints.down('md')]: {
      marginLeft: -24
    }
  },
  link: {
    textDecoration: 'none !important'
  },
  whiteWrapper: {
    backgroundColor: theme.globalColors.white,
  },
  patternWrapper: {
    position: 'relative',
    zIndex: 1,
    backgroundColor: theme.globalColors.lightestGrey,
    '&:before': {  
      content: '""',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      backgroundImage: 'url(https://sunstone-storage-public.s3.amazonaws.com/website-resources/Vector.png)',
      position: 'absolute',
      opacity: 0.25,
      zIndex: -1
    }
  },
  widthWrapper: globalStyles.widthWrapper(theme),
  widthWrapperFull: {
    width: '100%',
    ...globalStyles.widthWrapper(theme)
  },
  manishWrapper: {
    background: 'url(/homepage/Rectangle10.png)',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    paddingTop: theme.panelPaddingTopBottom * 2,
    paddingBottom: theme.panelPaddingTopBottom * 2
  },
  healingWrapper: {
    minHeight: 800,
    background: 'url(/homepage/healing.png)',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    paddingTop: theme.panelPaddingTopBottom,
    paddingBottom: theme.panelPaddingTopBottom,
    zIndex: 1,
    position: 'relative',
    '&:before': {
      content: '""',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      position: 'absolute',
      opacity: 0.15,
      backgroundColor: 'black',
      zIndex: -1
    }
  },
  interestedWrapper: {
    background: globalColors.blue,
    paddingTop: theme.panelPaddingTopBottom,
    paddingBottom: theme.panelPaddingTopBottom
  },
  newsletterWrapper: {
    background: globalColors.blue,
    paddingTop: `calc(${theme.panelPaddingTopBottom}px / 2)`,
    paddingBottom: `calc(${theme.panelPaddingTopBottom}px / 2)`
  },
  whiteWrapperHalf: {
    background: globalColors.white,
    paddingTop: `calc(${theme.panelPaddingTopBottom}px / 2)`,
    paddingBottom: `calc(${theme.panelPaddingTopBottom}px / 2)`
  }
});

// const StyledCardBack = withStyles(styles)(CardBack);
const StyledHome = withStyles(styles)(Home);