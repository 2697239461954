import React from "react";
import classNames from 'classnames';
import ReactGA from 'react-ga';

import { withStyles } from '@material-ui/core/styles';

import {
  Hidden,
  Grid,
  Typography
} from '@material-ui/core';

import { globalStyles, globalColors } from './styles/style-constants';
import { slide as Menu } from 'react-burger-menu'

const mobileStyles = {
  bmBurgerButton: {
    position: 'fixed',
    width: '36px',
    height: '30px',
    left: '36px',
    marginTop: -24
  },
  bmBurgerBars: {
    background: '#373a47'
  },
  bmBurgerBarsHover: {
    background: '#a90000'
  },
  bmCrossButton: {
    height: '24px',
    width: '24px'
  },
  bmCross: {

  },
  bmMenuWrap: {
    position: 'fixed',
    height: '100%',
    left: 0,
    top: 0
  },
  bmMenu: {
    background: '#fff',
    padding: '2.5em 1.5em 0',
    paddingTop: 0,
    fontSize: '1.15em',
    zInex: 1001
  },
  bmMorphShape: {
    fill: '#373a47'
  },
  bmItemList: {
    color: '#b8b7ad',
    padding: '0.8em'
  },
  bmItem: {
    display: 'inline-block',
    '&:focus': {
      outline: 'none'
    }
  },
  bmOverlay: {
    background: 'rgba(0, 0, 0, 0.3)',
    zIndex: 1000,
    top: 0,
    left: 0
  }
};

class MobileMenu extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
      menuOpen: false
    };
  }

  isMenuOpen(open) {
    this.setState({ menuOpen: open.isOpen });
  }

  render () {
    const { classes, menuImage, isHomePage } = this.props;
    const { menuOpen } = this.state;
    const closeImage = '/homepage/x.png';

    return (
      <Menu isOpen={menuOpen} onStateChange={this.isMenuOpen.bind(this)} disableAutoFocus styles={mobileStyles} customCrossIcon={ <img alt="menu" src={closeImage} /> } customBurgerIcon={ <img alt="menu" src={menuImage} /> }>
        { isHomePage && (
          <Grid container direction="column" spacing={2}>
            <a className={classes.link} href="/">
              <Grid item align="center" className="HomeHeaderItem">
                <img alt="full" width="120" src="/homepage/Full.png" />
                <br/>
                <br/>
              </Grid>
            </a>
            <Grid item className="HomeHeaderItem mobile-link" onClick={() => {
              this.setState({ menuOpen: false });
              document.getElementById("what-we-offer").scrollIntoView({ behavior: 'smooth'});
            }}>
              <Typography className="HomeHeaderButton">What we offer</Typography>
            </Grid>
            <Grid item className="HomeHeaderItem mobile-link" onClick={() => {
              this.setState({ menuOpen: false });
              document.getElementById("how-we-help").scrollIntoView({ behavior: 'smooth'});
            }}>
              <Typography className="HomeHeaderButton">Center for healing</Typography>
            </Grid>
            <Grid item className="HomeHeaderItem mobile-link"  onClick={() => {
              this.setState({ menuOpen: false });
              document.getElementById("news-events").scrollIntoView({ behavior: 'smooth'});
            }}>
              <Typography className="HomeHeaderButton">News and events</Typography>
            </Grid>
            <a className={classes.link} href="/team">
              <Grid item className="HomeHeaderItem mobile-link">
                <Typography className="HomeHeaderButton">Our team</Typography>
              </Grid>
            </a>
            <Grid item className="HomeHeaderItem mobile-link-2"  onClick={() => {
              this.setState({ menuOpen: false });
              document.getElementById("stay-in-touch").scrollIntoView({ behavior: 'smooth'});
            }}>
              <Typography style={{ border: `2px solid ${globalColors.blue}`, borderRadius: '31px', padding: '15px 20px' }} className="HomeHeaderButton">Get in touch</Typography>
            </Grid>
          </Grid>
        )}
        { !isHomePage && (
          <Grid container alignItems="center" direction="column" spacing={2}>
            <a href="/">
              <Grid item align="center" className="HomeHeaderItem">
                <img alt="full" width="120" src="/homepage/Full.png" />
                <br/>
                <br/>
              </Grid>
            </a>
            <a className={classes.link} href="/#what-we-offer">
              <Grid item className="HomeHeaderItem mobile-link">
                <Typography className="HomeHeaderButton">What we offer</Typography>
              </Grid>
            </a>
            <a className={classes.link} href="/#how-we-help">
              <Grid item className="HomeHeaderItem mobile-link">
                <Typography className="HomeHeaderButton">Center for healing</Typography>
              </Grid>
            </a>
            <a className={classes.link} href="/#news-events">
              <Grid item className="HomeHeaderItem mobile-link">
                <Typography className="HomeHeaderButton">News and events</Typography>
              </Grid>
            </a>
            <a className={classes.link} href="/team">
              <Grid item className="HomeHeaderItem mobile-link">
                <Typography className="HomeHeaderButton">Our team</Typography>
              </Grid>
            </a>
            <a className={classes.link} href="/#stay-in-touch">
              <Grid item className="HomeHeaderItem mobile-link-2">
                <Typography className="HomeHeaderButton">Get in touch</Typography>
              </Grid>
            </a>
          </Grid>
        )}
      </Menu>
    );
  }
}

class Header extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      prevScrollpos: 0,
      visible: true,
      isHomePage: true
    };
  }

  componentDidMount() {
    ReactGA.initialize('UA-188453287-1');
    ReactGA.pageview(window.location.pathname + window.location.search);

    window.addEventListener('scroll', this.handleScroll, { capture: true });
  
    this.setState({ isHomePage: window.location.pathname === "/" });
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll, { capture: true });
  }

  handleScroll = (e) => {
    const winScroll =
      document.body.scrollTop || document.documentElement.scrollTop

    const scrolled = winScroll;
    
    const { prevScrollpos } = this.state;

    const currentScrollPos = scrolled;
    const visible = prevScrollpos > currentScrollPos || currentScrollPos < 100;

    this.setState({
      prevScrollpos: scrolled,
      visible
    });
  };

  render() {
    const { classes, children, transparentHeaderY = 0 } = this.props;
    const { visible, prevScrollpos: scrollPosition, isHomePage } = this.state;

    const headerStyle = {
      transition: 'top 1s, background 0.3s',
      marginTop: 0,
      top: visible ? 0 : -750,
      paddingTop: 10,
      paddingBottom: 10,
      backgroundColor: Math.abs(scrollPosition) < transparentHeaderY ? 'transparent' : 'white',
      backgroundImage: Math.abs(scrollPosition) < transparentHeaderY ? '/homepage/Sunstone Therapies Logo White.svg' : '/homepage/Full.png',
      menuImage: Math.abs(scrollPosition) < transparentHeaderY ? "/homepage/menu.svg" : "/homepage/menu.png",
      headerButtonColor: Math.abs(scrollPosition) < transparentHeaderY ? "white": "#2C6277",
      zIndex: 1000
    };

    return (
      <header>
        <Hidden lgUp>
          <Grid container justify="center" alignItems="center" style={{ ...headerStyle }} className={classNames("HomeHeader")}>
            <Grid item>
              <StyledMobileMenu isHomePage={isHomePage} menuImage={headerStyle.menuImage} />
            </Grid>
            <a href="/">
              <Grid item className="HomeHeaderItem">
                <img alt="full" width="120" src={headerStyle.backgroundImage} />
              </Grid>
            </a>
          </Grid>
        </Hidden>
        <Hidden mdDown>
          <Grid container direction="row" justify="space-between" style={{ position: 'relative' }}>
            <Grid item>
              { isHomePage && (
                <Grid container justify="center" alignItems="center" style={{ ...headerStyle }} className={classNames("HomeHeader")}>
                  <Grid item className="HomeHeaderItem link" onClick={() => {
                    document.getElementById("what-we-offer").scrollIntoView({ behavior: 'smooth'});
                  }}>
                    <Typography className="HomeHeaderButton" style={{ color: headerStyle.headerButtonColor }}>What we offer</Typography>
                  </Grid>
                  <Grid item className="HomeHeaderItem link" onClick={() => {
                    document.getElementById("how-we-help").scrollIntoView({ behavior: 'smooth'});
                  }}>
                    <Typography className="HomeHeaderButton" style={{ color: headerStyle.headerButtonColor }}>Center for healing</Typography>
                  </Grid>
                  <Grid item className="HomeHeaderItem">
                    <a className={classes.link} href="/">
                      <img alt="full" src={headerStyle.backgroundImage} />
                    </a>
                  </Grid>
                  <Grid item className="HomeHeaderItem link" onClick={() => {
                    document.getElementById("news-events").scrollIntoView({ behavior: 'smooth'});
                  }}>
                    <Typography className="HomeHeaderButton" style={{ color: headerStyle.headerButtonColor }}>News and events</Typography>
                  </Grid>
                  <a className={classes.link} href="/team">
                    <Grid item className="HomeHeaderItem link">
                      <Typography className="HomeHeaderButton" style={{ color: headerStyle.headerButtonColor }}>Our team</Typography>
                    </Grid>
                  </a>
                </Grid>
              )}
              { !isHomePage && (
                <Grid container justify="center" alignItems="center" style={{ ...headerStyle }} className={classNames("HomeHeader")}>
                  <a className={classes.link} href="/#what-we-offer">
                    <Grid item className="HomeHeaderItem link">
                      <Typography className="HomeHeaderButton" style={{ color: headerStyle.headerButtonColor }}>What we offer</Typography>
                    </Grid>
                  </a>
                  <a className={classes.link} href="/#how-we-help">
                    <Grid item className="HomeHeaderItem link">
                      <Typography className="HomeHeaderButton" style={{ color: headerStyle.headerButtonColor }}>Center for healing</Typography>
                    </Grid>
                  </a>
                  <Grid item className="HomeHeaderItem">
                    <a className={classes.link} href="/">
                      <img alt="full" src={headerStyle.backgroundImage} />
                    </a>
                  </Grid>
                  <a className={classes.link} href="/#news-events">
                    <Grid item className="HomeHeaderItem link">
                      <Typography className="HomeHeaderButton" style={{ color: headerStyle.headerButtonColor }}>News and events</Typography>
                    </Grid>
                  </a>
                  <a className={classes.link} href="/team">
                    <Grid item className="HomeHeaderItem link">
                      <Typography className="HomeHeaderButton" style={{ color: headerStyle.headerButtonColor }}>Our team</Typography>
                    </Grid>
                  </a>
                </Grid>
              )}
              <div className="HomeHeaderItem GetInTouchButton link" style={{ width: 200, top: headerStyle.top + 60, border: `2px solid ${headerStyle.headerButtonColor}` }} onClick={() => {
                document.getElementById("stay-in-touch").scrollIntoView({ behavior: 'smooth'});
              }}>
                <Typography className="HomeHeaderButton" style={{ color: headerStyle.headerButtonColor }}>Get in touch</Typography>
              </div>
            </Grid>
          </Grid>
        </Hidden>
        { children }
      </header>
    )
  }
}

const styles = theme => ({
  fullScreenModal: {
    position: 'absolute',
    top: 0,
    right: 0,
    left: 0,
    bottom: 0,
    height: '100vh',
    background: 'white'
  },
  modalInner: {
    height: '100%',
    background: 'white'
  },
  teamWrapper: {

  },
  cardBackContainer: {
    background: globalColors.darkPink,
    color: `${globalColors.white} !important`,
    height: '100%',
    width: '100%',
    padding: 24
  },
  h1: {
    ...globalStyles.h1,
    color: globalColors.blue,
    paddingBottom: theme.h3HeaderPaddingTopBottom
  },
  h2: {
    ...globalStyles.h2,
    color: globalColors.blue
  },
  h2Mobile: {
    ...globalStyles.h2,
    color: globalColors.blue,
    fontSize: 24,
    marginTop: 8
  },
  h2White: {
    ...globalStyles.h2,
    color: globalColors.white
  },
  h3: {
    ...globalStyles.h3,
    color: globalColors.blue,
    paddingTop: theme.h3HeaderPaddingTopBottom,
    paddingBottom: theme.h3HeaderPaddingTopBottom
  },
  h3White: {
    ...globalStyles.h3,
    color: globalColors.white
  },
  h4White: {
    ...globalStyles.h4,
    color: globalColors.white
  },
  label1White: {
    ...globalStyles.label1,
    color: globalColors.white,
    marginTop: 8,
    marginBottom: 32,
    [theme.breakpoints.down('md')]: {
      marginTop: 4,
      marginBottom: 4
    }
  },
  body1White: {
    ...globalStyles.body1,
    color: globalColors.white
  },
  whatWeOffer: {
    ...globalStyles.h1,
    color: globalColors.blue,
    paddingBottom: theme.panelPaddingTopBottom
  },
  teamContainer: {
    [theme.breakpoints.down('md')]: {
      marginTop: '32px !important'
    }
  },
  signupItem: {
    [theme.breakpoints.down('md')]: {
      marginTop: 16
    }
  },
  teamItem: {
    minHeight: 360,
    [theme.breakpoints.down('md')]: {
      width: '100%',
      backgroundSize: 'cover',
      minHeight: 400
    }
  },
  areYouATherapist: {
    borderLeft: '1px solid white',
    [theme.breakpoints.down('sm')]: {
      borderLeft: 0
    }
  },
  secondPanelText: {
    paddingTop: theme.panelPaddingTopBottom,
    paddingBottom: theme.panelPaddingTopBottom,
    [theme.breakpoints.down('md')]: {
      paddingTop: `${theme.panelPaddingTopBottom}px !important`,
      paddingBottom: `${theme.panelPaddingTopBottom}px !important`,
    }
  },
  blankWrapper: {
    width: '100%',
    paddingTop: theme.panelPaddingTopBottom,
    paddingBottom: theme.panelPaddingTopBottom
  },
  offerItem: {
    [theme.breakpoints.down('md')]: {
      marginLeft: -24
    }
  },
  link: {
    textDecoration: 'none !important'
  },
  whiteWrapper: {
    backgroundColor: theme.globalColors.white,
  },
  patternWrapper: {
    position: 'relative',
    zIndex: 1,
    backgroundColor: theme.globalColors.lightestGrey,
    '&:before': {  
      content: '""',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      backgroundImage: 'url(https://sunstone-storage-public.s3.amazonaws.com/website-resources/Vector.png)',
      position: 'absolute',
      opacity: 0.25,
      zIndex: -1
    }
  },
  widthWrapper: globalStyles.widthWrapper(theme),
  widthWrapperFull: {
    width: '100%',
    ...globalStyles.widthWrapper(theme)
  },
  healingWrapper: {
    minHeight: 800,
    background: 'url(/homepage/healing.png)',
    backgroundSize: 'cover',
    paddingTop: theme.panelPaddingTopBottom,
    paddingBottom: theme.panelPaddingTopBottom,
    zIndex: 1,
    position: 'relative',
    '&:before': {
      content: '""',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      position: 'absolute',
      opacity: 0.15,
      backgroundColor: 'black',
      zIndex: -1
    }
  },
  interestedWrapper: {
    background: globalColors.blue,
    paddingTop: theme.panelPaddingTopBottom,
    paddingBottom: theme.panelPaddingTopBottom
  },
  newsletterWrapper: {
    background: globalColors.blue,
    paddingTop: `calc(${theme.panelPaddingTopBottom}px / 2)`,
    paddingBottom: `calc(${theme.panelPaddingTopBottom}px / 2)`
  },
  whiteWrapperHalf: {
    background: globalColors.white,
    paddingTop: `calc(${theme.panelPaddingTopBottom}px / 2)`,
    paddingBottom: `calc(${theme.panelPaddingTopBottom}px / 2)`
  }
});

const StyledMobileMenu = withStyles(styles)(MobileMenu);

export default withStyles(styles)(Header);
