// @flow
import axios from 'axios';

const GET = 'get';
const POST = 'post';
const DELETE = 'delete';

const initApi = () => {
  const api = axios.create();
  return api;
};

const axiosApi = initApi();

const getBody = (verb: string, body?: any): any => {
  // https://github.com/axios/axios/issues/897#issuecomment-343715381
  if (body !== undefined && verb === DELETE) {
    return { data: body };
  }

  return body;
};

const execute = async (verb: string, url: string, body?: any, additionalConfig: any) => {
  const config = {
    url,
    method: verb,
    data: getBody(verb, body),
    ...additionalConfig
  };
  const { status, headers, data } = await axiosApi.request(config);

  return {
    status,
    headers,
    data
  };
};

class SunstoneApi {
  async getTeam(type, body) {
    return execute(GET, `https://1kiiskeyu8.execute-api.us-east-1.amazonaws.com/dev/v1/team`);
  }

  async submitForm(type, body) {
    return execute(POST, `https://1kiiskeyu8.execute-api.us-east-1.amazonaws.com/dev/v1/collect-feedback?type=${type}`, body);
  }

  async newsletter(email) {
    return execute(POST, `https://1kiiskeyu8.execute-api.us-east-1.amazonaws.com/dev/v1/newsletter`, {
      'DATE SUBMITTED': new Date().toString(),
      'EMAIL': email
    });
  }

  async stayInTouch(firstName, lastName, email, message) {
    return execute(POST, `https://1kiiskeyu8.execute-api.us-east-1.amazonaws.com/dev/v1/stay-in-touch`, {
      'DATE SUBMITTED': new Date().toString(),
      'FIRST NAME': firstName,
      'LAST NAME': lastName,
      'EMAIL': email,
      'MESSAGE': message
    });
  }
}

export default new SunstoneApi();
