import React from "react";

import {
  Button
} from '@material-ui/core';

import { withStyles } from '@material-ui/core/styles';

function STButton({ classes, label, noPadding = false, children = {}, ...rest }) {
  return (
    <Button color="primary" 
      classes={{
        root: noPadding ? classes.buttonNoPadding : classes.button,
        label: classes.label,
      }}
      {...rest}
    >
      {children}
    </Button>
  )
}

const styles = theme => {
  return {
    button: theme.button.primary,
    buttonNoPadding: theme.button.primaryNoPadding,
    label: theme.button.label,
    bgColor: theme.button.primary.background
  }
};

export default withStyles(styles)(STButton);
