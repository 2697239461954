import React from "react";
import classNames from 'classnames';

import { withStyles } from '@material-ui/core/styles';

import Api from './Api';

import { globalStyles, globalColors } from './styles/style-constants';

import {
  Grid,
  Typography
} from '@material-ui/core';

import InputField from './InputField';

class Home extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      visible: true,
      email: '',
      prevScrollpos: 0,
      firstName: '',
      lastName: '',
      contactEmail: '',
      message: ''
    };
  }

  async signupNewsletter() {
    try {
      await Api.newsletter(this.state.email);
      alert('Thank you for signing up for the newsletter!');
    } catch (e) {
      alert('There was an error signing up for the newsletter. Please try again.');
    }
  }

  render() {
    const { classes } = this.props;

    return (
      <Grid container className={classNames(classes.newsletterWrapper)} style={{ marginTop: -2 }}>
        <Grid item align="center" className={classNames(classes.widthWrapperFull)}>
          <Grid container alignItems="center" spacing={3} justify="space-between" direction="row">
            <Grid item xs={12} sm>
              <Typography align="left" className={classes.h3White}>Sign up to receive our newsletter</Typography>
            </Grid>
            <Grid align="center" item style={{ marginTop: -3 }} className={classes.signupItem}>
              <InputField onArrowSubmit={this.signupNewsletter.bind(this)} value={this.state.email} onChange={e => this.setState({ email: e.target.value })} collapsed={true} labelText={"Enter your email"} includeArrow={true} style={{ borderRadius: 0, width: 284, border: 0, color: '#777', backgroundColor: 'white' }} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

const styles = theme => ({
  fullScreenModal: {
    position: 'absolute',
    top: 0,
    right: 0,
    left: 0,
    bottom: 0,
    height: '100vh',
    background: 'white'
  },
  modalInner: {
    height: '100%',
    background: 'white'
  },
  teamWrapper: {

  },
  cardBackContainer: {
    background: globalColors.darkPink,
    color: `${globalColors.white} !important`,
    height: '100%',
    width: '100%',
    padding: 24
  },
  h1: {
    ...globalStyles.h1(theme),
    color: globalColors.blue,
    paddingBottom: theme.h3HeaderPaddingTopBottom
  },
  h2: {
    ...globalStyles.h2(theme),
    color: globalColors.blue
  },
  h2Mobile: {
    ...globalStyles.h2(theme),
    color: globalColors.blue,
    fontSize: 24,
    marginTop: 8
  },
  h2White: {
    ...globalStyles.h2(theme),
    color: globalColors.white
  },
  h3: {
    ...globalStyles.h3(theme),
    color: globalColors.blue,
    paddingTop: theme.h3HeaderPaddingTopBottom,
    paddingBottom: theme.h3HeaderPaddingTopBottom
  },
  h3White: {
    ...globalStyles.h3(theme),
    color: globalColors.white
  },
  h4White: {
    ...globalStyles.h4,
    color: globalColors.white
  },
  label1White: {
    ...globalStyles.label1,
    color: globalColors.white,
    marginTop: 8,
    marginBottom: 32,
    [theme.breakpoints.down('md')]: {
      marginTop: 4,
      marginBottom: 4
    }
  },
  body1White: {
    ...globalStyles.body1,
    color: globalColors.white
  },
  whatWeOffer: {
    ...globalStyles.h1(theme),
    color: globalColors.blue,
    paddingBottom: theme.panelPaddingTopBottom
  },
  teamContainer: {
    [theme.breakpoints.down('md')]: {
      marginTop: '32px !important'
    }
  },
  signupItem: {
    [theme.breakpoints.down('md')]: {
      marginTop: 16
    }
  },
  teamItem: {
    minHeight: 360,
    [theme.breakpoints.down('md')]: {
      width: '100%',
      backgroundSize: 'cover',
      minHeight: 400
    }
  },
  areYouATherapist: {
    borderLeft: '1px solid white',
    [theme.breakpoints.down('sm')]: {
      borderLeft: 0
    }
  },
  secondPanelText: {
    paddingTop: theme.panelPaddingTopBottom,
    paddingBottom: theme.panelPaddingTopBottom,
    [theme.breakpoints.down('md')]: {
      paddingTop: `${theme.panelPaddingTopBottom}px !important`,
      paddingBottom: `${theme.panelPaddingTopBottom}px !important`,
    }
  },
  blankWrapper: {
    width: '100%',
    paddingTop: theme.panelPaddingTopBottom,
    paddingBottom: theme.panelPaddingTopBottom
  },
  offerItem: {
    [theme.breakpoints.down('md')]: {
      marginLeft: -24
    }
  },
  link: {
    textDecoration: 'none !important'
  },
  whiteWrapper: {
    backgroundColor: theme.globalColors.white,
  },
  patternWrapper: {
    position: 'relative',
    zIndex: 1,
    backgroundColor: theme.globalColors.lightestGrey,
    '&:before': {  
      content: '""',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      backgroundImage: 'url(https://sunstone-storage-public.s3.amazonaws.com/website-resources/Vector.png)',
      position: 'absolute',
      opacity: 0.25,
      zIndex: -1
    }
  },
  widthWrapper: globalStyles.widthWrapper(theme),
  widthWrapperFull: {
    width: '100%',
    ...globalStyles.widthWrapper(theme)
  },
  healingWrapper: {
    minHeight: 800,
    background: 'url(/homepage/healing.png)',
    backgroundSize: 'cover',
    paddingTop: theme.panelPaddingTopBottom,
    paddingBottom: theme.panelPaddingTopBottom,
    zIndex: 1,
    position: 'relative',
    '&:before': {
      content: '""',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      position: 'absolute',
      opacity: 0.15,
      backgroundColor: 'black',
      zIndex: -1
    }
  },
  interestedWrapper: {
    background: globalColors.blue,
    paddingTop: theme.panelPaddingTopBottom,
    paddingBottom: theme.panelPaddingTopBottom
  },
  newsletterWrapper: {
    background: globalColors.blue,
    paddingTop: `calc(${theme.panelPaddingTopBottom}px / 2)`,
    paddingBottom: `calc(${theme.panelPaddingTopBottom}px / 2)`
  },
  whiteWrapperHalf: {
    background: globalColors.white,
    paddingTop: `calc(${theme.panelPaddingTopBottom}px / 2)`,
    paddingBottom: `calc(${theme.panelPaddingTopBottom}px / 2)`
  }
});

export default withStyles(styles)(Home);
