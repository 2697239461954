import React, { Component } from 'react';

import {
  Grid,
  Typography
} from '@material-ui/core';

import classNames from 'classnames';

import { withStyles } from '@material-ui/core/styles';
import { globalColors } from './styles/style-constants';

class ResourceCard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      highlighted: true
    }
  }

  render() {
    const { classes, image, title, tagText, ctaText, tagColor = globalColors.darkPink, tagBackground = globalColors.white, description } = this.props;
    const { highlighted } = this.state;

    return (
      <Grid
        onMouseEnter={() => this.setState({ highlighted: true })}
        onMouseLeave={() => this.setState({ highlighted: true })} 
        container 
        direction="column" 
        justify="flex-start" 
        className={classNames(classes.wrapper, highlighted ? classes.selected : null)}
      >
        { image && (
          <div style={{overflow: 'hidden', marginBottom: 16}}>
            <img className={classes.responsiveImg} alt="resource-i" src={image} />
          </div>
          
        )}

        <Grid item>
          <span className={classes.tag} style={{ color: tagColor, backgroundColor: tagBackground, borderColor: tagColor }}>{tagText}</span>
        </Grid>
        <Typography variant="h4" className={classes.inputLabel}>{title}</Typography>
        <Typography className={classes.label1}>{description}</Typography>

        { ctaText && (
          <Typography className={classes.cta}>
            <span>{ctaText}</span>
          </Typography>
        )}
      </Grid>
    )
  }
};

const styles = theme => ({
  formControl: {
    width: '100%'
  },
  cta: {
    color: globalColors.blue,
    paddingTop: theme.h4HeaderPaddingTopBottom,
    ...theme.button.label,
    '& span': {
      position: 'relative',
    },
    '& span:after': {
      content: '""',
      position: 'absolute',
      top: -4,
      right: -28,
      height: 24,
      width: 24,
      background: 'url(/homepage/arrow-right.png)'
    }
  },
  wrapper: {
    cursor: 'pointer',
    padding: 32,
    minHeight: 324,
    transition: '0.5s',
    overflow: 'hidden'
  },
  selected: {
    backgroundColor: 'transparent',
    overflow: 'hidden',
    '&:hover': {
      backgroundColor: theme.globalColors.white,
      boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.1)',
    },
    '&:hover img': {
      transform: 'scale(1.2)',
      width: '100%',
      objectFit: 'cover',
      transition: '0.5s',
    }
  },
  responsiveImg: {
    maxWidth: '100%',
    width: '100%',
    marginBottom: 24
  },
  tag: {
    padding: '8px 24px',
    borderRadius: '20px',
    border: '0px solid black',
    ...theme.typography.label1
  },
  inputLabel: {
    marginTop: 24,
    color: globalColors.blue,
    paddingBottom: theme.h4HeaderPaddingTopBottom
  },
  label1: {
    ...theme.typography.label1
  },
  body1: theme.typography.body1,
  input: theme.form.input
});

export default withStyles(styles)(ResourceCard);
