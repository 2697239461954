import { remCalc } from '../util/style-util';

//Global (non-theme) colors
export const globalColors = {
  blackOnlyForButtons: '#000',
  white: '#FFF',
  digitalBlack: '#1c1c1c',
  mediumBlackIconOnly: '#494949',
  mediumGrey: '#707070',
  lightestGrey: '#f9f9f9',
  greyDisabled: '#d2d2d2',
  darkPink: '#E63B74',
  darkPink20: 'rgb(250,216,227)',
  orange: '#FF876D',
  lightPink: '#FFBEBE',
  sage: '#BEBCAE',
  blue: '#2C6277',
  blue20: '#D5E0E4',
  black: '#000',
  darkGrey: '#777',
  error: '#E64614',
  success: '#2C7033',
  inFocus: '#51ABFF',
  grey: '#eee'
};


//Global (non-theme) styles
export const globalStyles = {
  display: (theme) => {
    return {
      fontFamily: 'Kumbh Sans',
      color: globalColors.digitalBlack,
      fontStyle: "normal",
      fontWeight: 700,
      fontSize: "38px",
      lineHeight: "120%",
      letterSpacing: "1px"
    }
  },
  h1: (theme) => {
    return {
      fontFamily: "Kumbh Sans",
      fontStyle: "normal",
      fontWeight: "300",
      fontSize: "38px",
      lineHeight: "120%",
      letterSpacing: "1px",
      [theme.breakpoints.down('md')]: {
        fontSize: "28px",
      }
    }
  },
  h2: (theme) => {
    return {
      fontFamily: "Kumbh Sans",
      fontStyle: "normal",
      fontWeight: "300",
      fontSize: "32px",
      lineHeight: "120%",
      letterSpacing: "1px",
      [theme.breakpoints.down('md')]: {
        fontSize: "24px",
      }
    }
  },
  h3: (theme) => {
    return {
      fontFamily: "Kumbh Sans",
      fontStyle: "normal",
      fontWeight: "400",
      fontSize: "21px",
      lineHeight: "120%",
      letterSpacing: "1px",
      [theme.breakpoints.down('md')]: {
        fontSize: "21px",
      }
    }
  },
  h4: {
    fontFamily: "Kumbh Sans",
    fontStyle: "normal",
    fontWeight: "300",
    fontSize: "18px",
    lineHeight: "120%",
    letterSpacing: "1px"
  },
  h5: {
    fontFamily: 'Kumbh Sans',
    color: globalColors.digitalBlack,
    fontSize: remCalc(18),
    fontWeight: 600,
    lineHeight: 'normal'
  },
  button: {
    fontFamily: 'Kumbh Sans',
    fontSize: remCalc(18),
    fontWeight: 600,
    lineHeight: 'normal',
    color: globalColors.digitalBlack,
    textTransform: 'uppercase',
    letterSpacing: remCalc(.5)
  },
  label1: {
    fontFamily: "Kumbh Sans",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "14px",
    lineHeight: "120%",
    letterSpacing: "1px",
    textTransform: "uppercase"
  },
  label2: {
    fontFamily: "Kumbh Sans",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "12px",
    lineHeight: "120%",
    letterSpacing: "1px",
    textTransform: "uppercase"
  },
  body1: {
    fontFamily: 'Roboto',
    fontSize: remCalc(16),
    color: globalColors.mediumBlackIconOnly,
    lineHeight: 1.38
  },
  body2: {
    fontFamily: 'Roboto',
    fontSize: remCalc(14),
    color: globalColors.mediumBlackIconOnly,
    lineHeight: 1.29
  },
  body3: {
    fontFamily: 'Roboto',
    fontSize: remCalc(12),
    color: globalColors.mediumBlackIconOnly,
    lineHeight: 1.33
  },
  widthWrapper: (theme) => {
    return (
      {
        maxWidth: remCalc(1440),
        margin: '0 auto',
        [theme.breakpoints.down('md')]: {
          padding: remCalc(0, 16)
        }
      }
    )
  },
  responsiveContainer: (theme) => {
    return {
      margin: '0 auto',
      [theme.breakpoints.up('lg')]: {
        width: remCalc(1248),
      },
      [theme.breakpoints.between('sm', 'md')]: {
        width: remCalc(600),
      },
      [theme.breakpoints.down('xs')]: {
        width: remCalc(343),
      }
    }
  }
}