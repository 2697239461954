import React from "react";

import {
  Grid,
  OutlinedInput,
  FormControl,
  FormHelperText,
  Typography
} from '@material-ui/core';

import { withStyles } from '@material-ui/core/styles';
import { globalColors } from './styles/style-constants';

function InputField({ classes, placeholder = '', includeArrow, onArrowSubmit, type = 'input', labelText, helperText = null, collapsed = false, ...rest }) {

  return (
    <Grid container direction="column" justify="flex-start" className={classes.wrapper}>
      { !collapsed && (
        <Grid item>
          <Typography className={classes.inputLabel}>{labelText}</Typography>
        </Grid>
      )}
      <Grid item>
        <FormControl className={classes.formControl}>
          <Grid container style={{border: includeArrow ? '1px solid white' : null}} alignItems="center" justify="center" direction="row">
            <Grid item xs={includeArrow ? null : 12}>
              { type === 'input' && (
                <OutlinedInput fullWidth placeholder={collapsed ? labelText : placeholder} className={classes.input} id="my-input" aria-describedby="my-helper-text" {...rest} />
              )}
              { type === 'textarea' && (
                <textarea placeholder={collapsed ? labelText : ''} className={classes.input} id="my-input" aria-describedby="my-helper-text" {...rest} />
              )}
            </Grid>
            { includeArrow && (
              <Grid item onClick={onArrowSubmit}>
                <span style={{cursor: 'pointer', color: 'white', float: 'right', paddingTop: 5, paddingLeft: 12, paddingRight: 12 }}><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-send"><line x1="22" y1="2" x2="11" y2="13"></line><polygon points="22 2 15 22 11 13 2 9 22 2"></polygon></svg></span>
              </Grid>
            )}
          </Grid>
          { helperText && (
            <FormHelperText id="my-helper-text">{helperText}</FormHelperText>
          )}
        </FormControl>
      </Grid>
    </Grid>
  )
}

const styles = theme => ({
  formControl: {
    width: '100%'
  },
  wrapper: {
    backgroundColor: 'transparent',
    color: '#777',
  },
  inputLabel: {
    color: globalColors.blue,
    ...theme.typography.label2,
    marginBottom: 4
  },
  body1: theme.typography.body1,
  input: theme.form.input
});

export default withStyles(styles)(InputField);
