import React from "react";

import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  TextField
 } from '@material-ui/core';

import './App.css';
import Api from './Api';

const credentials = [
  'MD',
  'APRN',
  'MA',
  'BC',
  'PhD',
  'BSN',
  'RN',
  'PA',
  'LCSW',
  'LMFT',
  'LCSW-C',
  'PA-C',
  'LCSW-C RYT',
  'MPH',
  'LCPC'
];

const trainings = [
  'MD',
  'APRN',
  'MA',
  'BC',
  'PhD',
  'BSN',
  'RN',
  'PA',
  'LCSW',
  'LMFT',
  'LCSW-C',
  'PA-C',
  'LCSW-C RYT',
  'MPH',
  'LCPC'
];

const interests = [
  'Patient Experience',
  'Ketamine',
  'Whole Person Care',
  'Oncology',
  'Training',
  'Depression',
  'Research',
  'Resources',
  'Drug Trials',
  'PTSD',
  'Insights',
  'Addiction',
  'MDMA',
  'Other',
  'Psilocybin'
];

class IndividualForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedConditions: {},
      selectedCredentials: {}
    };
  }

  submitForm() {
    Api.submitForm('individual', {
      'SUBMIT DATE': new Date().toString(),
      'FIRST NAME': this.state.firstName,
      'LAST NAME': this.state.lastName,
      'PHONE NUMBER': this.state.phoneNumber,
      'EMAIL ADDRESS': this.state.emailAddress,
      'ZIP CODE': this.state.zipCode,
      'WILLING TO TRAVEL 50 MILES': this.state.willingToTravel,
      'DIAGNOSED CONDITIONS': Object.keys(this.state.selectedConditions).join(', '),
    });

    alert('Thank you for your submission.');
  }

  selectCondition(c) {
    const { selectedConditions } = this.state;

    if (selectedConditions[c]) {
      delete selectedConditions[c];
    } else {
      selectedConditions[c] = true;
    }

    this.setState({ selectedConditions });
  }

  selectCredential() {
    
  }

  render() {
    const { firstName, lastName, phoneNumber, emailAddress, state, city, county, organization, title, yearsInPractice, references } = this.state;
    const { selectedCredentials, selectedInterests, selectedTraining } = this.state;

    return (
      <Grid container className="App">
        <Grid xs={12} style={{ paddingLeft: 16 }}>
          <h1>Get in touch.</h1>
          <p>We'd like to learn more about you and your interests.</p>
          <br/>
          <br/>
        </Grid>

        <Grid xs={12} sm={6}>
          <div className="field-wrapper">
            <FormControl fullWidth component="fieldset">
              <FormLabel align="left" component="legend" className="form-label">First Name</FormLabel>
              <TextField
                onChange={e => this.setState({ firstName: e.target.value })}
                value={firstName}
                variant="outlined"
              />
            </FormControl>
          </div>
        </Grid>

        <Grid xs={12} sm={6}>
          <div className="field-wrapper">
            <FormControl fullWidth component="fieldset">
              <FormLabel align="left" component="legend" className="form-label">Last Name</FormLabel>
              <TextField
                onChange={e => this.setState({ lastName: e.target.value })}
                value={lastName}
                variant="outlined"
              />
            </FormControl>
          </div>
        </Grid>

        <Grid xs={12} sm={6}>
          <div className="field-wrapper">
            <FormControl fullWidth component="fieldset">
              <FormLabel align="left" component="legend" className="form-label">Phone Number</FormLabel>
              <TextField
                onChange={e => this.setState({ phoneNumber: e.target.value })}
                value={phoneNumber}
                variant="outlined"
              />
            </FormControl>
          </div>
        </Grid>

        <Grid xs={12} sm={6}>
          <div className="field-wrapper">
            <FormControl fullWidth component="fieldset">
              <FormLabel align="left" component="legend" className="form-label">Email Address</FormLabel>
              <TextField
                onChange={e => this.setState({ emailAddress: e.target.value })}
                value={emailAddress}
                variant="outlined"
              />
            </FormControl>
          </div>
        </Grid>

        <Grid xs={12} sm={6}>
          <Grid container>
            <Grid xs={12} sm={6}>
              <div className="field-wrapper">
                <FormControl fullWidth component="fieldset">
                  <FormLabel align="left" component="legend" className="form-label">State</FormLabel>
                  <TextField
                    onChange={e => this.setState({ state: e.target.value })}
                    value={state}
                    variant="outlined"
                  />
                </FormControl>
              </div>
            </Grid>
            <Grid xs={12} sm={6}>
              <div className="field-wrapper">
                <FormControl fullWidth component="fieldset">
                  <FormLabel align="left" component="legend" className="form-label">County</FormLabel>
                  <TextField
                    onChange={e => this.setState({ county: e.target.value })}
                    value={county}
                    variant="outlined"
                  />
                </FormControl>
              </div>
            </Grid>
          </Grid>
        </Grid>

        <Grid xs={12} sm={6}>
          <div className="field-wrapper">
            <FormControl fullWidth component="fieldset">
              <FormLabel align="left" component="legend" className="form-label">City/Town</FormLabel>
              <TextField
                onChange={e => this.setState({ city: e.target.value })}
                value={city}
                variant="outlined"
              />
            </FormControl>
          </div>
        </Grid>

        <Grid xs={12} sm={6}>
          <div className="field-wrapper">
            <FormControl fullWidth component="fieldset">
              <FormLabel align="left" component="legend" className="form-label">Organization/Facility</FormLabel>
              <TextField
                onChange={e => this.setState({ organization: e.target.value })}
                value={organization}
                variant="outlined"
              />
            </FormControl>
          </div>
        </Grid>

        <Grid xs={12} sm={6}>
          <div className="field-wrapper">
            <FormControl fullWidth component="fieldset">
              <FormLabel align="left" component="legend" className="form-label">Title</FormLabel>
              <TextField
                onChange={e => this.setState({ title: e.target.value })}
                value={title}
                variant="outlined"
              />
            </FormControl>
          </div>
        </Grid>

        <Grid xs={12} sm={6}>
          <div className="field-wrapper">
            <FormControl fullWidth component="fieldset">
              <FormLabel align="left" component="legend" className="form-label">Years in Practice</FormLabel>
              <TextField
                onChange={e => this.setState({ yearsInPractice: e.target.value })}
                value={yearsInPractice}
                variant="outlined"
              />
            </FormControl>
          </div>
        </Grid>

        <Grid xs={12} sm={6}>
          <div className="field-wrapper">
            <FormControl fullWidth component="fieldset">
              <FormLabel align="left" component="legend" className="form-label">References (Name/Email)</FormLabel>
              <TextField
                onChange={e => this.setState({ references: e.target.value })}
                value={references}
                variant="outlined"
              />
            </FormControl>
          </div>
        </Grid>

        <Grid xs={12} sm={6}>
          <div className="field-wrapper">
            <FormControl fullWidth component="fieldset">
              <FormLabel align="left" component="legend" className="form-label">credentials/degrees</FormLabel>
              <Grid container>
                { credentials.map(c => (
                  <Grid xs={12} sm={6}>
                    <FormGroup>
                      <FormControlLabel
                        control={<Checkbox value={c} checked={selectedCredentials[c] != null} onChange={this.selectCredential.bind(this, c)} />}
                        label={c}
                      />
                    </FormGroup>
                  </Grid>
                ))}
              </Grid>
            </FormControl>
          </div>
        </Grid>

        <Grid xs={12} sm={6}>
          <div className="field-wrapper">
            <FormControl fullWidth component="fieldset">
              <FormLabel align="left" component="legend" className="form-label">Interests (select all that apply)</FormLabel>
              <Grid container>
                { interests.map(c => (
                  <Grid xs={12} sm={6}>
                    <FormGroup>
                      <FormControlLabel
                        control={<Checkbox value={c} checked={selectedInterests[c] != null} onChange={this.selectInterest.bind(this, c)} />}
                        label={c}
                      />
                    </FormGroup>
                  </Grid>
                ))}
              </Grid>
            </FormControl>
          </div>
        </Grid>

        <Grid xs={12} sm={6}>
          <div className="field-wrapper">
            <FormControl fullWidth component="fieldset">
              <FormLabel align="left" component="legend" className="form-label">TRAINING / CERTIFICATION</FormLabel>
              <Grid container>
                { trainings.map(c => (
                  <Grid xs={12} sm={12}>
                    <FormGroup>
                      <FormControlLabel
                        control={<Checkbox value={c} checked={selectedTraining[c] != null} onChange={this.selectTraining.bind(this, c)} />}
                        label={c}
                      />
                    </FormGroup>
                  </Grid>
                ))}
              </Grid>
            </FormControl>
          </div>
        </Grid>

        <Grid xs={12} style={{ paddingLeft: 16 }}>
          <div style={{ textAlign: 'left', marginTop: 32 }}>
            <button
              onClick={this.submitForm.bind(this)}
              className="App-button"
            >
              Submit
            </button>
          </div>
        </Grid>
      </Grid>
    );
  }
}

export default IndividualForm;