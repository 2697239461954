import { globalColors } from '../style-constants';
const theme = {
  extendedTheme: () => ({
    palette: {
      primary: {
        main: '#E63B74'
      },
      primaryBtnHover: {
        main: '#cc774e',
      },
      progressColor: {
        colorPrimary: globalColors.lightestGrey,
        barColorPrimary: globalColors.blue
      },
      buttonBackground: {
        main: globalColors.blue
      },
      buttonText: {
        main: '#1c1c1c'
      },
      secondary: {
        main: '#444054'
      },
      interaction: {
        main: '#B84C16'
      },
      text: {
        primary: '#1c1c1c'
      },
      link: {
        primary: '#1c1c1c'
      }
    },
    button: {
      primary: {
        background: globalColors.blue,
        color: globalColors.white,
        border: `1px solid ${globalColors.blue}`,
        borderRadius: 31,
        fontSize: 18,
        padding: '14px 70px',
        "&:hover": {
          backgroundColor: globalColors.white,
          color: globalColors.blue,
          border: `1px solid ${globalColors.blue}`
        },
        "&:disabled": {
          backgroundColor: globalColors.blue,
          color: globalColors.white,
          opacity: 0.6
        }
      },
      primaryNoPadding: {
        background: globalColors.blue,
        color: globalColors.white,
        borderRadius: 31,
        "&:hover": {
          backgroundColor: globalColors.blue
        },
        "&:disabled": {
          backgroundColor: globalColors.blue,
          color: globalColors.white,
          opacity: 0.6
        }
      },
      label: {
        textTransform: 'none',
        fontFamily: 'Roboto',
        fontWeight: 500,
        letterSpacing: '1px'
      }
    },
    typography: {
      useNextVariants: true,
      color: globalColors.black,
      fontFamily: [
        '"Kumbh Sans"',
        '"Roboto"',
        '"Helvetica"',
        '"Arial"',
        'sans-serif'
      ].join(','),
    },
    form: {
      label: {
        textAlign: 'left'
      },
      input: {
        borderRadius: 8,
        border: `1px solid ${globalColors.darkGrey}`,
        padding: '0px',
        '& :active': {
          boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.1)'
        }
      }
    }
  }),
  typography: (theme, remCalc) => {
    return {
      h1: {
        fontFamily: "Kumbh Sans",
        fontStyle: "normal",
        fontWeight: "bold",
        color: globalColors.digitalBlack,
        fontSize: "54px",
        lineHeight: "120%",
        letterSpacing: "1px",
        [theme.breakpoints.up('sm')]: {
          fontSize: remCalc(54),
          lineHeight: 1.11
        },
        [theme.breakpoints.down('xs')]: {
          fontSize: remCalc(34),
          lineHeight: 1.24
        }
      },
      h2: {
        fontFamily: "Kumbh Sans",
        fontStyle: "normal",
        fontWeight: 300,
        fontSize: "40px",
        lineHeight: "120%",
        letterSpacing: "1px",
        [theme.breakpoints.up('sm')]: {
          fontSize: remCalc(40),
          lineHeight: 1.15
        },
        [theme.breakpoints.down('xs')]: {
          fontSize: remCalc(30),
          lineHeight: 1.2
        }
      },
      h3: {
        fontFamily: "Kumbh Sans",
        fontStyle: "normal",
        fontWeight: "bold",
        fontSize: "24px",
        lineHeight: "120%",
        letterSpacing: "1px",
        color: globalColors.digitalBlack,
      },
      button: {
        fontFamily: 'Kumbh Sans',
        fontSize: remCalc(16),
        fontWeight: 600,
        lineHeight: 'normal',
        color: globalColors.digitalBlack,
        textTransform: 'uppercase',
        letterSpacing: remCalc(.5)
      },
      h4: {
        fontFamily: "Kumbh Sans",
        fontStyle: "normal",
        fontWeight: "300",
        fontSize: "21px",
        lineHeight: "120%",
        letterSpacing: "1px"
      },
      label1: {
        fontFamily: "Kumbh Sans",
        fontStyle: "normal",
        fontWeight: "700",
        fontSize: "14px",
        lineHeight: "120%",
        letterSpacing: "1px",
        textTransform: "uppercase"
      },
      label2: {
        fontFamily: "Kumbh Sans",
        fontStyle: "normal",
        fontWeight: "bold",
        fontSize: "12px",
        lineHeight: "120%",
        letterSpacing: "1px",
        textTransform: "uppercase"
      },
      body1: {
        fontFamily: 'Roboto',
        fontSize: remCalc(16),
        color: globalColors.mediumBlackIconOnly,
        lineHeight: 1.38
      },
      body2: {
        fontFamily: 'Roboto',
        fontSize: remCalc(14),
        color: globalColors.mediumBlackIconOnly,
        lineHeight: 1.29
      },
      body3: {
        fontFamily: 'Roboto',
        fontSize: remCalc(12),
        color: globalColors.mediumBlackIconOnly,
        lineHeight: 1.33
      }
    }
  },
  extraTypography: (theme, remCalc) => {
    return {
      hero: {
        fontFamily: 'Swiss721BT',
        fontWeight: 'bold',
        color: '#1c1c1c',
        [theme.breakpoints.up('sm')]: {
          fontSize: remCalc(44),
          lineHeight: 1.09
        },
        [theme.breakpoints.down('xs')]: {
          fontSize: remCalc(44),
          lineHeight: 1.16
        }
      },
      card: {
        fontFamily: 'Swiss721BT',
        fontWeight: 'bold',
        color: '#1c1c1c',
        [theme.breakpoints.up('sm')]: {
          fontSize: remCalc(38),
          lineHeight: 1.16
        },
        [theme.breakpoints.down('xs')]: {
          fontSize: remCalc(28),
          lineHeight: 1.21
        }
      }
    }
  }

};

export default theme;
