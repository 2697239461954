import React from "react";
import classNames from 'classnames';

import { withStyles } from '@material-ui/core/styles';

import { globalStyles, globalColors } from './styles/style-constants';

import {
  Grid
} from '@material-ui/core';

function Footer ({ classes }) {
  return (
    <Grid container alignItems="center" justify="center" className={classNames(classes.whiteWrapper)}>
      <Grid item align="center" className={classNames(classes.widthWrapperFull, classes.whiteWrapperHalf)}>
        <Grid container alignItems="center" justify="space-between">
          <Grid item>
            <Grid container spacing={2}>
              <Grid item>
                <a href="https://www.instagram.com/sunstonegrams/" target="_blank" rel="noopener noreferrer">
                  <img alt="full" src="/homepage/instagram.png" />
                </a>
              </Grid>
              <Grid item>
                <a href="https://www.linkedin.com/company/sunstone-therapies/" target="_blank" rel="noopener noreferrer">
                  <img alt="full" src="/homepage/linkedin.png" />
                </a>
              </Grid>
              <Grid item>
                <a href="https://medium.com/@sunstonetherapies" target="_blank" rel="noopener noreferrer">
                  <img alt="full" src="/homepage/medium.png" />
                </a>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <img alt="full" src="/homepage/Full.png" />
          </Grid>
          <Grid item>
            @2021 Sunstone Therapies
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

const styles = theme => ({
  fullScreenModal: {
    position: 'absolute',
    top: 0,
    right: 0,
    left: 0,
    bottom: 0,
    height: '100vh',
    background: 'white'
  },
  modalInner: {
    height: '100%',
    background: 'white'
  },
  teamWrapper: {

  },
  cardBackContainer: {
    background: globalColors.darkPink,
    color: `${globalColors.white} !important`,
    height: '100%',
    width: '100%',
    padding: 24
  },
  h1: {
    ...globalStyles.h1,
    color: globalColors.blue,
    paddingBottom: theme.h3HeaderPaddingTopBottom
  },
  h2: {
    ...globalStyles.h2,
    color: globalColors.blue
  },
  h2Mobile: {
    ...globalStyles.h2,
    color: globalColors.blue,
    fontSize: 24,
    marginTop: 8
  },
  h2White: {
    ...globalStyles.h2,
    color: globalColors.white
  },
  h3: {
    ...globalStyles.h3,
    color: globalColors.blue,
    paddingTop: theme.h3HeaderPaddingTopBottom,
    paddingBottom: theme.h3HeaderPaddingTopBottom
  },
  h3White: {
    ...globalStyles.h3,
    color: globalColors.white
  },
  h4White: {
    ...globalStyles.h4,
    color: globalColors.white
  },
  label1White: {
    ...globalStyles.label1,
    color: globalColors.white,
    marginTop: 8,
    marginBottom: 32,
    [theme.breakpoints.down('md')]: {
      marginTop: 4,
      marginBottom: 4
    }
  },
  body1White: {
    ...globalStyles.body1,
    color: globalColors.white
  },
  whatWeOffer: {
    ...globalStyles.h1,
    color: globalColors.blue,
    paddingBottom: theme.panelPaddingTopBottom
  },
  teamContainer: {
    [theme.breakpoints.down('md')]: {
      marginTop: '32px !important'
    }
  },
  signupItem: {
    [theme.breakpoints.down('md')]: {
      marginTop: 16
    }
  },
  teamItem: {
    minHeight: 360,
    [theme.breakpoints.down('md')]: {
      width: '100%',
      backgroundSize: 'cover',
      minHeight: 400
    }
  },
  areYouATherapist: {
    borderLeft: '1px solid white',
    [theme.breakpoints.down('sm')]: {
      borderLeft: 0
    }
  },
  secondPanelText: {
    paddingTop: theme.panelPaddingTopBottom,
    paddingBottom: theme.panelPaddingTopBottom,
    [theme.breakpoints.down('md')]: {
      paddingTop: `${theme.panelPaddingTopBottom}px !important`,
      paddingBottom: `${theme.panelPaddingTopBottom}px !important`,
    }
  },
  blankWrapper: {
    width: '100%',
    paddingTop: theme.panelPaddingTopBottom,
    paddingBottom: theme.panelPaddingTopBottom
  },
  offerItem: {
    [theme.breakpoints.down('md')]: {
      marginLeft: -24
    }
  },
  link: {
    textDecoration: 'none !important'
  },
  whiteWrapper: {
    backgroundColor: theme.globalColors.white,
  },
  patternWrapper: {
    position: 'relative',
    zIndex: 1,
    backgroundColor: theme.globalColors.lightestGrey,
    '&:before': {  
      content: '""',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      backgroundImage: 'url(https://sunstone-storage-public.s3.amazonaws.com/website-resources/Vector.png)',
      position: 'absolute',
      opacity: 0.25,
      zIndex: -1
    }
  },
  widthWrapper: globalStyles.widthWrapper(theme),
  widthWrapperFull: {
    width: '100%',
    ...globalStyles.widthWrapper(theme)
  },
  healingWrapper: {
    minHeight: 800,
    background: 'url(/homepage/healing.png)',
    backgroundSize: 'cover',
    paddingTop: theme.panelPaddingTopBottom,
    paddingBottom: theme.panelPaddingTopBottom,
    zIndex: 1,
    position: 'relative',
    '&:before': {
      content: '""',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      position: 'absolute',
      opacity: 0.15,
      backgroundColor: 'black',
      zIndex: -1
    }
  },
  interestedWrapper: {
    background: globalColors.blue,
    paddingTop: theme.panelPaddingTopBottom,
    paddingBottom: theme.panelPaddingTopBottom
  },
  newsletterWrapper: {
    background: globalColors.blue,
    paddingTop: `calc(${theme.panelPaddingTopBottom}px / 2)`,
    paddingBottom: `calc(${theme.panelPaddingTopBottom}px / 2)`
  },
  whiteWrapperHalf: {
    background: globalColors.white,
    paddingTop: `calc(${theme.panelPaddingTopBottom}px / 2)`,
    paddingBottom: `calc(${theme.panelPaddingTopBottom}px / 2)`
  }
});

export default withStyles(styles)(Footer);
